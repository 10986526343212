table {
    width: 100%;
    border: none;
    margin-bottom: 20px;
    border-collapse: separate;
}
table thead th {
    font-weight: bold;
    text-align: left;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #dddddd;
    text-align: center;
}
table tr th:first-child, .table tr td:first-child {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #dddddd;
}
table tr th:last-child, .table tr td:last-child {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #dddddd;
}
table thead tr th:first-child {
    border-radius: 20px 0 0 0;
}
table thead tr th:last-child {
    border-radius: 0 20px 0 0;
}
table tbody td {
    text-align: left;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    vertical-align: top;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
}
.border-none tbody td {
    border-right: 0px;
    border-left: 0px;
}
table tbody tr:nth-child(even) {
    background: #F8F8F8;
}
table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 20px;
    border-left: 1px solid #ddd;
}
table tbody tr th:first-child {
    text-align: center;
}
table tbody tr td {
    vertical-align: middle;
}
table tbody tr:last-child td:last-child {
    border-radius: 0 0 20px 0;
    border-right: 1px solid #ddd;
}


.checkout-table th, .checkout-table td {
    text-align: center;
}

.checkout-table td {
    white-space: nowrap;
}

#receipt-items-table {
    margin-top: 10px;
}

#receipt-items-table th {
    border: none;
    text-align: center;
}

#receipt-items-table td {
    border: none;
    text-align: center;
}
.container-sales {
    border: 2px solid #CCCCCC; /* Серая рамка */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); /* Тень вокруг */
    padding: 20px; /* Добавление отступов внутри div */
    border-radius: 10px; /* Округление углов */
}

.receipt {
    max-width: 100%;
    margin: 0 auto;
    border: 1px solid #bdbdbd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-receipt {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.table-receipt th, .table-receipt td {
    padding: 8px;
    text-align: left;
}

.total {
    margin-top: 10px;
    font-weight: bold;
}

.seller-info {
    margin-top: 20px;
}

.signature {
    margin-top: 40px;
}
.border-red td{
    border: 1px solid red !important;
}

/* Модальное окно */
.modal2 {
    display: none;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

/* Контент модального окна */
.modal-content2 {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    overflow-x: hidden;
    border-radius: 10px; /* Округление углов */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Тень */
}

/* Заголовок модального окна */
.modal-header2 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-top-left-radius: 10px; /* Округление верхних углов */
    border-top-right-radius: 10px; /* Округление верхних углов */
}

/* Кнопка закрытия */
.close {
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

/* Модальное окно: узкий */
.modal-narrow2>.modal-content2 {
    width: 30%; /* Произвольная ширина */
    max-width: none;
}

/* Модальное окно: средний */
.modal-medium2>.modal-content2 {
    width: 50%; /* Произвольная ширина */
    max-width: none;
}

/* Модальное окно: большой */
.modal-large2>.modal-content2 {
    width: 70%; /* Произвольная ширина */
    max-width: none;
}

/* Модальное окно: на весь экран */
.modal-fullscreen>.modal-content2 {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 10px;
    border-radius: 0;
}
.input-group {
    z-index: 0;
}

/* Изменение фона и цвета текста для активной вкладки */
.nav-tabs .nav-item .nav-link.active {
    background-color: #212529; /* Цвет фона */
    color: #ffffff; /* Цвет текста */
    margin-left: 5px;
    margin-right: 5px;
    padding: 15px;
}

/* Изменение цвета текста для неактивной вкладки */
.nav-tabs .nav-item .nav-link {
    color: #000000; /* Цвет текста */
    margin-left: 5px;
    margin-right: 5px;
}

.imagebutton {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 40px;
    color: #abd6fc;
    border-color: #abd6fc;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
}
.stat-card {
    transition: transform 0.2s;
}
.stat-card:hover {
    transform: scale(1.05);
}